import React, { useCallback, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const locale = {
  monday: 'Mo',
  tuesday: 'Di',
  wednesday: 'Mi',
  thursday: 'Do',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'So',
  ok: 'OK',
  today: 'heute',
  yesterday: 'gestern',
  last7Days: 'Letzte Woche',
  formattedMonthPattern: 'MMM yyyy',
  formattedDayPattern: 'd.M.yyyy',
};

const DateRangePickerComponent = ({
  defaultValue,
  onChange,
  ranges,
  disableDateRange,
}) => {
  const [value, setValue] = useState((s) => {
    return defaultValue() || [];
  });
  const isValidDate = (date) => {
    if (!date || date == 'undefined' || date == 'Invalid Date') {
      return false;
    }
    const momentDate = moment(date);
    return momentDate.isValid() && momentDate.year() >= 1970;
  };

  const updateParentValue = useCallback(
    (newValue) => {
      if (!newValue || newValue?.length == 0) {
        onChange([]);
        return;
      }

      if (isValidDate(newValue[0]) && isValidDate(newValue[1])) {
        const parsedValue =
          newValue.map((v) =>
            !v ? undefined : moment(v).local().format('YYYY-MM-DD')
          ) || [];
        onChange(parsedValue);
      }
    },
    [onChange, isValidDate]
  );

  const onDateChange = (newValue) => {
    setValue(newValue);
  };

  const handleOk = useCallback(
    (newValue) => {
      setValue(newValue);
      updateParentValue(newValue);
    },
    [updateParentValue]
  );

  const handleClean = useCallback(() => {
    setValue([]);
    updateParentValue([]);
  }, [updateParentValue]);

  const renderValue = (value) =>
    !value
      ? null
      : value.map((v) => moment(v).local().format('D.M.YY')).join(' – ');

  const onExited = () => {
    if (value?.length > 1) {
      if (isValidDate(value[0]) && isValidDate(value[1])) {
        updateParentValue(value);
      }
    }
  };

  return (
    <DateRangePicker
      block
      isoWeek={true}
      format="dd.MM.yyyy"
      locale={locale}
      ranges={ranges}
      value={value}
      renderValue={renderValue}
      character={' - '}
      placeholder="&nbsp;"
      onChange={onDateChange}
      onOk={handleOk}
      onClean={handleClean}
      shouldDisableDate={disableDateRange}
      onExited={onExited}
    />
  );
};

export default DateRangePickerComponent;
